html {
    font-size: 62.5%;   /* 62.5% of 16px = 10px */
}

.flexcenter {
    align-items: center;
    justify-content: center;
}

.flexcolumn {
    display: flex;
    flex-direction: column;
}

.flexrow {
    display: flex;
    flex-direction: row;
}

.App {
    align-items: center;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    min-width: 100%;
}

.no-scrollbars::-webkit-scrollbar {
    display: none;
}

.scrollbars::-webkit-scrollbar {
    display: block;
}

.minimal-scrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}
.minimal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
}

.App-header {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.App-header-large {
    height: 6rem;
    margin-top: 1rem;
    width: 100%;
}

.App-logo {
    margin-left: 2%;
    margin-top: 2%;
    width: 10%;
}

.App-logo-large {
    height: 100%;
}

.App-link {
    color: #09d3ac;
}

.HelperText-container {
    font-size: 1.1rem;
    line-height: 1.5rem;
    width: 90%;
    text-align: center;
}

.LoadingScreen {
    align-items: center;
    background-color: #32C884;
    display: flex;
    font-size: 6rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.TextLarge {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.8rem;
}

.TextNormal {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.4rem;
}

.TextSmall {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    line-height: 1.2rem;
}

.TextBolded {
    font-weight: bold;
}

.TextCenter {
    text-align: center;
}

a,
[role=button],
[data-focusable=true] {
    outline-color: #7438FF;
}

/* Used to disable Chrome's automatic highlighting of text inputs */
.disable-outline input,
.disable-outline textarea {
    outline-width: 0;
}

.hidden {
    display: none;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
